import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBAnimation } from 'mdbreact'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import PlatformDetails from '../components/platformDetails'
import Cta from '../components/cta'

export default ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <div id="sub-page">
      <Layout>
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src}
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.fluid}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}            
          type="root"
        />

        {post.frontmatter.section ? (
          <section className="bg-white pt-5">
            <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
              {post.frontmatter.section.map((sections, index) => {
                return (
                  <PlatformDetails
                    key={index}
                    title={sections.title}
                    subtitle={sections.subtitle}
                    description={sections.description}
                    image={(sections.techimage && sections.techimage != "") ? sections.techimage.childImageSharp.fluid : sections.image.childImageSharp.fluid}
                    imageAltText={sections.alttext}
                    link={sections.link ? sections.link : sections.document.publicURL}
                    linktext={sections.linktext}
                    eol={sections.eol}
                    specs={sections.feature}
                    divider={
                      post.frontmatter.section.length > 1 && index + 1 < post.frontmatter.section.length
                        ? 'border-bottom'
                        : 'border-none'
                    }
                  />
                )
              })}
            </MDBAnimation>
          </section>
        ) : (
          ''
        )}

        <section className="bg-blue-green-gradient">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <Cta
                ctatitle={post.frontmatter.section[0].eol ? 'Contact UNICOM Engineering' : 'Request a quote'}
                ctadescription="The UNICOM Engineering team is standing by to learn about your business and help you identify the technology and services that can transform your solutions and provide a competitive advantage. The quicker we start to engage the faster this can happen. We’ve provided some baseline information above, but we are much more engaging in person."
                ctalink={
                  post.frontmatter.section[0].eol 
                    ? 'https://go.unicomengineering.com/Contact-Us?pg-name=' 
                    : 'https://go.unicomengineering.com/Request-A-Quote.html?pg-name='+ post.frontmatter.section[0].title .replace(/ /g, '-') .replace(/[”"'()]/g, '')
                }
                ctalinktext={post.frontmatter.section[0].eol ? 'Schedule a consultation' : 'Request a quote'}
              />
            </MDBContainer>
          </MDBAnimation>
        </section>
      </Layout>
    </div>
  )
}
export const query = graphql`
  query PlatformBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }     
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alttext        
        ctatext
        ctaslug      
        section {
          title
          subtitle
          description
          alttext
          image {
            childImageSharp {
              fluid(maxWidth: 960, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          techimage {
            childImageSharp {
              fluid(maxWidth: 960, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          linktext
          link
          eol
          document {
            publicURL
          }
          feature {
            title
            description
            icon
          }
        }
      }
    }
  }
`
